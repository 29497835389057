<template>
  <b-row
    class="invoice-preview"
  >
    <b-col
      cols="12"
    >
      <b-card>
        <h6>Transaction with Paypal information</h6>
        <span v-if="fetching">
          <table>
            <tr><td>Payment Status</td>
              <td>:
                <b-spinner
                  small
                  type="grow"
                />
              </td>
            </tr>
          </table>
        </span>
        <span v-else>
          <table v-if="isOk">
            <tr><td>Payment Status</td><td>: <b>{{ status }}</b></td></tr>
            <tr><td>Invoice No</td><td>: <b>{{ invoice_number }}</b></td></tr>
            <tr><td>Trasaction Reference No.</td><td>: <b>{{ transactionRef }}</b></td></tr>
          </table>
          <table v-else>
            <tr><td>Payment Status</td><td>: <b>{{ status }}</b></td></tr>
          </table>
        </span>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
  },
  data() {
    return {
      status: '',
      isOk: false,
      transactionRef: '',
      invoice_number: '',
      fetching: true,
    }
  },
  mounted() {
    this.paypalStatus()
    const refreshId = setInterval(() => {
      if (this.status) {
        this.fetching = false
        clearInterval(refreshId)
      }
    }, 1000)
  },
  methods: {
    async paypalStatus() {
      try {
        const res = await this.$store.dispatch('invoice/getPaypalStatus')
        this.status = res.status
        this.transactionRef = res.transactionRef
        this.invoice_number = res.invoice_number
        this.isOk = true
      } catch (e) {
        this.status = 'Transaction with Paypal failed.'
        const statusObj = JSON.parse(e.response.data.message)
        if (typeof statusObj.message !== 'undefined') this.status = statusObj.message
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

</style>
